import * as React from "react";
import { Box, Typography, Link } from "@mui/material";
import Layout from "../components/Layout";
import lines from "../images/index/lines.svg";
import { Link as RouterLink } from "gatsby";

const NotFoundPage = () => {
  return (
    <Layout>
      <Box component="div" sx={{ py: 30, px: 2, textAlign: "center" }}>
        <Typography variant="h1" component="h2" sx={{ mb: 3.75 }}>
          404{" "}
          <Box component="span" sx={{ display: "block" }}>
            Oops! Page unavailable.
          </Box>
        </Typography>
        <Typography sx={{ mb: 3.75 }}>
          Continue to browse{" "}
          <Link
            component={RouterLink}
            to="/"
            sx={{ textDecoration: "underline" }}
          >
            here.
          </Link>
        </Typography>
      </Box>
      <Box
        component="img"
        src={lines}
        sx={{
          position: "absolute",
          right: "0",
          bottom: "80px",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
    </Layout>
  );
};

export default NotFoundPage;
